// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import kpfsketch from "../images/kpfsketch.jpg"

const Aufgaben = (props: PageProps) => (
  <Layout>
    <SEO title="KPF.NRW Aufgaben" />
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              KPF.NRW
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Unsere Aufgaben
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                   <p className="mt-8 text-xl text-gray-500 leading-8">
                       Welche Sorgfaltspflichten haben Museen, Archive und Bibliotheken im Umgang mit der Herkunft ihrer
                       Sammlungen? Was benötigt die Herkunftserforschung im Jahr 2022?
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Die KPF.NRW bietet Information, Beratung, Austausch & Kooperation um:
                    </p>
                    <ul className="mt-8 text-xl text-gray-500 leading-8">
                        <li>Provenienzforschung zu initiieren und etablieren,</li>
                        <li>spezifische Bedarfe festzustellen,</li>
                        <li>sammlungsübergreifende Desiderate zu ermitteln und damit insgesamt</li>
                        <li>die Forschungsinfrastruktur und die Wissenskommunikation zu verbessern.</li>
                    </ul>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Im Fokus unserer Arbeit stehen die historischen Unrechtskontexte, vordringlich des Nationalsozialismus,
                        der ehemaligen Sowjetischen Besatzungszone (SBZ) und der Deutschen Demokratischen Republik (DDR)
                        und des Kolonialismus.
                    </p>
                    <img className="w-full rounded-lg" src={kpfsketch} />
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Die KPF.NRW unterstützt, vernetzt und berät Sie gern bei allen Fragen und Belangen zur Provenienzforschung. 
                        Unser gemeinsames Ziel ist eine systematische, nachhaltige und effiziente Provenienzforschung in NRW. Wir beraten Sie gerne, unterstützen und vernetzen
                        Sie mit den richtigen Partner:innen, damit wir gemeinsam eine systematische, nachhaltige und
                        effiziente Provenienzforschung in NRW etablieren.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Mit der Gründung der KPF.NRW entsteht in Kooperation mit dem nordrhein-westfälischen Ministerium
                        für Kultur und Wissenschaft sowie den Landschaftsverbänden Rheinland und Westfalen-Lippe eine
                        zentrale Serviceeinrichtung, die die Forschungsaktivitäten in Nordrhein-Westfalen rund um das
                        Thema der Herkunftserforschung bündelt. Die KPF.NRW richtet sich an Museen, 
                        Bibliotheken und Archive, aber auch an Privatpersonen, den Kunsthandel und die Community im 
                        Bereich der Provenienzforschung. Bitte zögern Sie nicht, uns zu kontaktieren.
                    </p>
         </div>
      </div>
    </div>
  </Layout>
)

export default Aufgaben
